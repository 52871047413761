<template>
	<div style="height: 100vh" class="m-5">
		<div class="columns is-centered is-vcentered">
			<div class="column is-4-desktop is-6-tablet is-full-mobile">
				<div class="card">
					<div
						class="card-header"
						style="
							background: linear-gradient(
								89.31deg,
								#211895 1.38%,
								#904bff 97.6%
							);
						">
						<div
							v-if="bankList && bankList.length > 0"
							class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
							<img width="150" :src="`/logos/${site}/logo.svg`" />
							<img
								:width="
									$route.query.withdrawType === 'TETHER'
										? 75
										: 150
								"
								:src="getLogo.logo" />
						</div>
					</div>
					<div class="card-content is-centered" v-if="tab === 0">
						<p
							class="mb-4 p-3 has-text-centered has-background-danger has-text-white">
							{{ errMsg }}
						</p>
					</div>
					<section class="section is-main-section" v-if="tab === 1">
						<form @submit.prevent="save">
							<b-field
								v-if="form.withdrawType === 'PAPARA'"
								label="Ad & Soyad"
								:message="'Papara hesabınızdaki ad soyad ile aynı olmalıdır'">
								<b-input
									v-model="form.accountName"
									type="is-small" />
							</b-field>
							<b-field
								v-if="
									form.withdrawType === 'BANK_TRANSFER' ||
									form.withdrawType === 'FOREX'
								"
								label="Ad & Soyad"
								:message="'Banka hesabınızdaki ad soyad ile aynı olmalıdır'">
								<b-input
									v-model="form.accountName"
									type="is-small" />
							</b-field>
							<b-field
								v-if="form.withdrawType === 'PAYFIX'"
								label="Ad & Soyad"
								:message="'Payfix hesabınızdaki ad soyad ile aynı olmalıdır'">
								<b-input
									v-model="form.accountName"
									type="is-small" />
							</b-field>
							<b-field :label="'Hesap No'">
								<b-input
									v-model="form.iban"
									type="is-small"
									disabled />
							</b-field>
							<b-field label="Yatırım tutarı">
								<b-input
									v-model="form.amount"
									type="is-small"
									disabled />
							</b-field>
							<div class="is-flex is-full is-justify-content-end">
								<b-button
									class="mr-3"
									type="is-danger"
									size="is-medium"
									@click="windowClose"
									>Kapat</b-button
								>
								<b-button
									:disabled="!isValid"
									expanded
									type="is-primary"
									size="is-medium"
									native-type="submit"
									>Devam et</b-button
								>
							</div>
						</form>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CardComponent from "@/components/CardComponent"
	import {
		IntegrationService,
		TransactionService,
		UserService,
	} from "@/services"

	export default {
		name: "WithdrawIframe",
		components: { CardComponent },
		data() {
			return {
				loading: false,
				error: false,
				errMsg: "",
				tab: 0,
				bankList: {},
				form: {
					appKey: this.$route.query.appKey,
					appSecret: this.$route.query.appSecret,
					username: this.$route.query.username,
					name: this.$route.query.name,
					bankId: null,
					description: null,
					withdrawType: this.$route.query.withdrawType,
					amount: this.$route.query.amount,
					userId: this.$route.query.userId,
					iban: this.$route.query.iban,
					accountName: this.$route.query.name,
					bankName: this.$route.query.bankName,
					processId: this.$route.query.processId,
					fromCurrency:
						this.$route.query.withdrawType === "TETHER"
							? "USDT"
							: "TRY",
				},
			}
		},
		computed: {
			isValid() {
				return (
					this.form.appKey &&
						this.form.appSecret &&
						this.form.userId &&
						this.form.username &&
						this.form.name &&
						this.form.amount >= 0 &&
						this.form.iban,
					this.form.bankName,
					this.form.processId,
					this.form.withdrawType
				)
			},
			getLogo() {
				let logo = process.env.VUE_APP_SITE_URL + "logos/banks/papara.png"
				if (this.$route.query.withdrawType === "PAYFIX")
					logo = process.env.VUE_APP_SITE_URL + "logos/banks/payfix.png"
				if (this.$route.query.withdrawType === "TETHER")
					logo =
						process.env.VUE_APP_SITE_URL +
						"logos/" +
						this.site +
						"/crypto.svg"
				if (
					this.form.withdrawType === "BANK_TRANSFER" ||
					this.form.withdrawType === "FOREX"
				) {
					logo = this.bankList.find(
						(item) => item.code === this.form.iban.slice(6, 9)
					)?.logo || { logo: null }
				}
				console.log(logo)
				return { logo }
			},
		},
		mounted() {
			const html = document.getElementsByTagName("html")[0]
			html.classList = []
			this.init()
		},
		methods: {
			init() {
				UserService.providerCheck(this.form)
					.then(() => {
						this.tab = 1
						this.getBankList()
					})
					.catch(() => (this.error = true))
			},
			async getBankList() {
				const request = {}
				this.bankList = []
				const { data } = await TransactionService.hashCreate(
					request,
					this.form.appSecret
				)
				const bankListResponse = await IntegrationService.bankList(
					request,
					data,
					this.form.appKey
				)
				this.bankList = bankListResponse.data
			},
			async save() {
				const request = {
					username: this.form.username,
					name: this.form.accountName,
					accountName: this.form.accountName,
					iban: this.form.iban,
					amount: this.form.amount,
					userId: this.form.userId,
					withdrawType: this.form.withdrawType,
					bankId: this.form.bankId,
					jwt: this.form.jwt,
					description: this.form.description,
					fromCurrency: this.form.fromCurrency,
				}
				const { data } = await TransactionService.hashCreate(
					request,
					this.form.appSecret
				)
				IntegrationService.withdraw(request, data, this.form.appKey)
					.then((res) => {
						window.location.href = res.data.withdrawUrl
					})
					.catch((err) => {
						this.tab = 0
						this.errMsg = err.message
					})
			},
		},
	}
</script>
